import React, {useState} from "react";
import "../css/Home.css";

function Home() {
    const [activeContent, setActiveContent] = useState("Skills");

    const handleContentChange = (content) => {
    
        setActiveContent(content);
    };
  
      
    return (
        <div className="container ">
            <div className="row">
                <div className="col-12 col-md-6">
                    <img  src="images/imgpro.jpg" alt="Placeholder" />
                </div>
                <div className="col-12 col-md-5">
                    <div className="row item-row ">
                        <div className="col navbar-item loading-effect unselectable "
                        style={{color: activeContent === "Skills" ? "#39ff14" : ""}}
                         onMouseEnter={() => handleContentChange("Skills")}>
                            Skills
                        </div>
                        <div
                            className="col navbar-item loading-effect unselectable "
                            style={{color: activeContent === "Experience" ? "#39ff14" : ""}}
                            onMouseEnter={() => handleContentChange("Experience")}
                        >
                            Experience
                        </div>
                        <div
                            className="col navbar-item loading-effect unselectable "
                            style={{color: activeContent === "Education" ? "#39ff14" : ""}}
                            onMouseEnter={() => handleContentChange("Education")}
                        >
                            Education
                        </div>
                    </div>
                    <div className="row mt-3">
                        {activeContent === "Skills" && (
                            <div className="col  skill-div">
                                <p className="specs-heading">AI Developer</p>
                                <p className="specs-description">Design & develop AI solutions</p>
                                <p className="specs-heading">Frontend Development</p>
                                <p className="specs-description">Web app development</p>
                                <p className="specs-heading">Backend Development</p>
                                <p className="specs-description">Developing backend software solutions</p>
                                <p className="specs-heading">Robotics & Automation</p>
                                <p className="specs-description">Hardware and software base electronic solution</p>
                            </div>
                        )}
                        {activeContent === "Experience" && (
                            <div className="col  exp-div">
                                <p className="specs-heading">Tarento Technologies Pvt Ltd</p>
                                <p className="specs-description">Associate software engineer</p>
                                <p className="specs-heading">Freelancer</p>
                                <p className="specs-description">Web app development</p>
                                <p className="specs-heading">Custom Projects</p>
                                <p className="specs-description">Custom robotics, IoT and embedded system projects</p>
                            </div>
                        )}
                        {activeContent === "Education" && (
                            <div className="col  edu-div">
                                <p className="specs-heading">Master of Technology </p>
                                <p className="specs-description">Artificial Intelligence Engineering</p>
                                <p className="specs-heading">Bachelor of Technology </p>
                                <p className="specs-description">Electronics and Communication Engineering</p>
                                <p className="specs-heading">12th Grade</p>
                                <p className="specs-description">Computer Science</p>
            
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
