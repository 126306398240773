import React from "react";

import "../App.css";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-md  fixed-top mb-5  glowing-border"
      style={{ backgroundColor: "black" }}
    >
      <div className="container-fluid">
        <div >
          <img className="imag" src="/images/icon_1.png" alt="icon"/>
        </div>
        <div className="navbar-brand">
          <h1>Dilavar Davood</h1>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
