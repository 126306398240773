import React from "react";
import "../App.css";
import Home from "../pages/Home";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Services from "../components/services";



function Dashboard() {
    return (
        <div>
            <Navbar />
            <Home />
            <Services/>
            <Footer />
        </div>
    );
}

export default Dashboard;
