import React, {useState} from "react";
import "../css/Services.css";

const Services = () => {
    const [iconName, setIconName] = useState("_");
    const servicesData = [
        {
            id: 1,
            icon: `/images/ai${1 === iconName ? iconName : "_"}.png`,
            title: "AI Solutions",
            description: "Give me a problem statement and will helps you to achieve it using Artificial intelligence",
        },
        {
            id: 2,
            icon: `/images/web${2 === iconName ? iconName : "_"}.png`,
            title: "Web Development",
            description: "A complete end to end development of web based application",
        },
        {
            id: 3,
            icon: `/images/robot${3 === iconName ? iconName : "_"}.png`,
            title: "Robotics and Electronic Projects",
            description: "A custom made solution as per the needs with the help of cutting edge technology",
        },
    ];
    const handleServiceFocus = (serviceId) => {
        setIconName(serviceId);
    };

    return (
        <div className="main-div-card">
            <div className="my-services-text mt-3 mb-3 ">My Services</div>
            <div className="services-container ">
                {servicesData.map((service) => (
                    <div
                        key={service.id}
                        className="service-card"
                        onMouseEnter={() => handleServiceFocus(service.id)}
                        onMouseLeave={() => setIconName("_")}
                    >
                        <img src={service.icon} alt={`Service ${service.id}`} className="icon" />
                        <h3 className="title">{service.title}</h3>
                        <p className="description">{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
