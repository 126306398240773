import React from "react";
import "./App.css";
import Dashboard from "./pages/Dashboard";

function App() {

    return (
        <div>
            <Dashboard/>
        </div>
    );
}

export default App;
