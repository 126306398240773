import React from "react";
import { SocialIcon } from "react-social-icons";
import "../css/footer.css";

const Footer = () => {
  return (
    <footer className="text-center text-lg-start">
      <div className="container p-4 foo-row">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <div className="footer-section">
              <h5 className="text-uppercase">Contact Me</h5>
              <p>dilavardavood@gmail.com</p>
              <p>+91 9544967645</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <div className="footer-section">
              <h5 className="text-uppercase">Addresses</h5>
              <p>Pokkakkillath House</p>
              <p>Post Office : Valapad - 680567</p>
              <p>Thrissur, Kerala, India</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <div className="footer-section">
              <h5 className="text-uppercase">Social</h5>
              <ul className="list-inline social-media-buttons">
                <li className="list-inline-item pe-2">
                  <SocialIcon url="https://www.facebook.com/Dilavardavood?mibextid=ZbWKwL" />
                </li>
                <li className="list-inline-item pe-2">
                  <SocialIcon url="https://instagram.com/dilavar_davood?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" />
                </li>
                <li className="list-inline-item pe-2">
                  <SocialIcon url="https://www.linkedin.com/in/dilavar-davood-14b2611b2" />
                </li>
                <li className="list-inline-item">
                  <SocialIcon url="https://www.github.com/dilavardavood" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center p-3 foo-bottom-bar">
        &copy; {new Date().getFullYear()} dilavardavood. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
